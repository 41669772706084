<template>
  <b-sidebar
    id="add-new-diagnostic-image-category-sidebar"
    :visible="isAddNewDiagnosticImageCategorySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      val => $emit('update:is-add-new-diagnostic-image-category-sidebar-active', val)
    ">
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t('Add') }} {{ $t('New') }} Package</h5>

        <i class="fas fa-times ml-1 cursor-pointer" @click="hide"></i>
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Select Clinic -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm">
          <!-- Facilities -->
          <validation-provider
            #default="validationContext"
            name="facilities"
            rules="required">
            <b-form-group label="Facilities" label-for="Facilities">
              <treeselect
                v-model="stateData.facility_id"
                class="rounded"
                style="background: #fff"
                :options="facilityOptions"
                :normalizer="normalizer" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type of Visit -->
          <validation-provider
            #default="validationContext"
            name="Type visit"
            rules="required">
            <b-form-group label-for="type_visit">
              <label for="type_consult">{{ $t('Type visit') }}</label>
              <treeselect
                id="type_visit"
                v-model="stateData.type_visit_id"
                :state="getValidationState(validationContext)"
                :options="optionsTypeVisit"
                :normalizer="normalizer" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Text message -->
          <validation-provider
            #default="validationContext"
            name="message">
            <b-form-group :label="$t('Text message')" label-for="Text message">
              <b-form-textarea
                id="message"
                v-model="stateData.text_message"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Text message"
                class="custom-textarea" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
              <span v-if="!loading">{{ $t('Add') }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button type="button" variant="outline-danger" @click="hide">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
      <ToastNotification ref="toastRef" />
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import formValidation from '@/core/utils/forms/form-validation'
import ToastNotification from '@/components/ToastNotification'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SpinnerLoading from '@/components/SpinnerLoading'

import { required } from '@validations'
import { ref, onMounted } from '@vue/composition-api'

import axiosAC from '@/core/services/apiInsurance/admin/consults/messages'
import axiosTV from '@/core/services/apiInsurance/admin/consults/visits'
// '@/core/services/apiInsurance/admin/consults/visits'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,
    ToastNotification,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isAddNewDiagnosticImageCategorySidebarActive',
    event: 'update:is-add-new-diagnostic-image-category-sidebar-active',
  },
  props: {
    isAddNewDiagnosticImageCategorySidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
    }
  },
  setup(props, { emit }) {
    const facilityOptions = JSON.parse(atob(localStorage.getItem('setFacilityOptions')))
    const optionsTypeVisit = ref([])
    const optionsStatus = [
      { name: 'Active', id: true },
      { name: 'Inactive', id: false },
    ]
    const stateDataTem = {}
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)))

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem))
    }
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData)

    const loading = ref(false)
    const toastRef = ref(null)

    onMounted(() => {
      loadTypeVisit()
    })

    const loadTypeVisit = () => {
      axiosTV.visitList(10).then(({ data }) => {
        optionsTypeVisit.value = data
      })
    }

    const onSubmit = () => {
      //   loading.value = true
      const datos = {
        ...stateData.value,
      }
      axiosAC
        .messageCreate(datos)
        .then(({ registro }) => {
          loading.value = false
          resetuserData()
          emit('createDiagnosticImageCategory', { status: true, registro })
        })
        .catch(() => {
          loading.value = false
          emit('createDiagnosticImageCategory', { status: false })
        })
    }
    return {
      loading,
      stateData,
      optionsStatus,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      toastRef,
      facilityOptions,
      optionsTypeVisit,
    }
  },
}
</script>

<style>
.custom-textarea {
  min-height: 200px;
}
</style>
